<template>
  <validation-observer
    ref="refFormObserver"
  >
    <b-form
      class="list-view product-checkout"
      @submit.prevent="submit()"
    >
      <b-row>
        <b-col
          cols="12"
          class="mb-2"
        >
          <h5 class="mb-0">
            Dados pessoais
          </h5>
          <small class="text-muted">Informe seus dados</small>
        </b-col>
        <b-col md="6">
          <validation-provider
            v-slot="{ errors }"
            name="name"
            rules="required"
          >
            <b-form-group
              label="Nome"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="customer.name"
                placeholder="Seu nome"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            v-slot="{ errors }"
            name="cpf"
            rules="required|integer|digits:11"
          >
            <b-form-group
              label="CPF"
              label-for="cpf"
            >
              <b-form-input
                id="cpf"
                v-model="customer.cpf"
                placeholder="111.222.333-44"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            v-slot="{ errors }"
            name="email"
            rules="required|email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="customer.email"
                placeholder="email@email.com"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            v-slot="{ errors }"
            name="phone_number"
            vid="phone_number"
            rules="required"
          >
            <b-form-group
              label="Celular"
              label-for="phone_number"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <vue-tel-input
                id="phone_number"
                v-model="customer.phone_number"
                name="phone_number"
                :style="errors.length > 0 ? 'border-color: red;' : ''"
                style="height:37px; border-radius:6px;"
                default-country="BR"
                mode="international"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            v-slot="{ errors }"
            name="birth"
            rules="required"
          >
            <b-form-group
              label-for="birth"
              label="Data de nascimento"
            >
              <flat-pickr
                id="birth"
                v-model="customer.birth"
                class="form-control"
                :config="{ dateFormat: 'd/m/Y'}"
                placeholder="DD/MM/YYYY"
                :style="errors.length > 0 ? 'border-color: red;' : ''"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
      <b-button
        variant="primary"
        @click="$emit('previous-step')"
      >
        Voltar
      </b-button>

      <b-button
        variant="primary"
        class="float-right"
        type="submit"
      >
        Continuar
      </b-button>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import flatPickr from 'vue-flatpickr-component'
import { mapActions } from 'vuex'
import PaymentServices from './paymentServices'

export default {
  components: {
    BRow,
    BCol,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    flatPickr,
  },
  props: {},
  data() {
    return {
      customer: {
        name: '',
        cpf: '',
        email: '',
        phone_number: '',
        birth: '',
        birthFormated: '',
        user_id: ''
      }
    }
  },
  computed: {
    customerState() {
      return this.$store.state.payment.customer
    }
  },
  watch: {

  },
  async mounted() {
    await this.ActionSetCustomer({
      name: '',
      cpf: '',
      email: '',
      phone_number: '',
      birth: '',
      user_id: '',
    })

    this.customerStorage = await JSON.parse(localStorage.getItem('customer'))

    const associate = await JSON.parse(localStorage.getItem('items'))

    await this.ActionSetCustomer({
      name: this.customerStorage.name,
      cpf: this.customerStorage.cpf,
      email: this.customerStorage.email,
      phone_number: this.customerStorage.phone,
      birth: this.customerStorage.birthDate,
      user_id: this.customerStorage.user_id
    })

    // eslint-disable-next-line no-alert
    console.log(this.customerState)

    this.customer.name = this.customerState.name
    this.customer.cpf = this.customerState.cpf
    this.customer.email = this.customerState.email
    this.customer.phone_number = this.formatPhoneNumber(this.customerState.phone_number)
    this.customer.birth = this.formatDate(this.customerState.birth, 'PT')
    this.customer.birthFormated = this.customerState.birth
    this.customer.user_id = this.customerStorage.user_id
  },
  methods: {
    ...mapActions('payment', [
      'ActionSetCustomer'
    ]),
    submit() {
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          await await this.ActionSetCustomer({
            name: this.customer.name,
            cpf: this.customer.cpf,
            email: this.customer.email,
            phone_number: this.customer.phone_number,
            birth: this.customer.birth,
            birthFormated: this.formatDate(this.customer.birth, 'US'),
            user_id: this.customerStorage.user_id
          })

          console.log(this.customerState)
          await PaymentServices().saveUser(this.customerState)

          this.$emit('next-step')
        }
      })
    },
    // eslint-disable-next-line consistent-return
    formatDate(date, toLang) {
      const dateDefault = date

      if (date) {
        if (toLang === 'US') {
          const XXI = dateDefault.split('/')
          const dateUS = `${XXI[2]}-${XXI[1]}-${XXI[0]}`
          return dateUS
        } if (toLang === 'PT') {
          const XXI = dateDefault.split('-')
          const datePT = `${XXI[2]}-${XXI[1]}-${XXI[0]}`
          return datePT
        }
      }
    },
    formatPhoneNumber(number) {
      console.log(number)
      if (number.indexOf(' ') >= 0) {
        const phoneNumber = number.split(' ')
        return `${phoneNumber[1]}${phoneNumber[2]}${phoneNumber[3]}`
      }
      return number
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
